'use client'
import { useState, useRef, useEffect } from 'react'
import { cx } from 'class-variance-authority'

import { ChevronDown } from 'react-feather'

import { TAccordion } from './Accordion.type'
import styles from './Accordion.module.css'

function Accordion(props: TAccordion) {
  const { className, children, title, Icon, customHeader, classNames, defaultIsOpen = false } = props
  const childRef = useRef<HTMLDivElement>(null)
  const [open, setOpen] = useState(defaultIsOpen)
  const [contentHeight, setContentHeight] = useState(0)

  useEffect(() => {
    setContentHeight(childRef.current?.scrollHeight || 0)
  }, [children])

  const toggleClick = () => {
    setOpen(!open)
  }

  return (
    <div className={cx(styles.mainContainer, className)}>
      <div className={cx(styles.title, classNames?.title)} onClick={toggleClick}>
        {customHeader && <div className={classNames?.customHeader}>{customHeader}</div>}
        {Icon && <Icon className={styles.icon} size={20} stroke-width={1.5} />}
        <h4 className={styles.titleText}>{title}</h4>
        <ChevronDown
          className={styles.chevron}
          size={24}
          strokeWidth={1.5}
          style={{
            transform: open ? 'rotate(180deg)' : 'rotate(0deg)',
          }}
        />
      </div>
      <div
        className={cx(styles.expandable, open ? classNames?.expandable : null)}
        style={{ maxHeight: open ? contentHeight : 0 }}
      >
        <div className={cx(styles.childContainer, classNames?.content)} ref={childRef}>
          {children}
        </div>
      </div>
    </div>
  )
}

export default Accordion
