'use client'
import { useState, ChangeEvent, ReactNode } from 'react'
import { TRatingStarsProps } from './RatingStars.type'
import { cx } from 'class-variance-authority'
import styles from './RatingStars.module.css'
import { Star } from 'react-feather'

export default function RatingStars(props: TRatingStarsProps) {
  const {
    id,
    score = 0,
    onChange,
    isEditable = true,
    withDescription = true,
    size = 'small',
    starCount = 4,
    className,
    isBolder,
    ...rest
  } = props

  const [selectedStar, setSelectedStar] = useState<number>(score)
  const [isClicked, setIsClicked] = useState<boolean>(false)
  const [clickedScore, setClickedScore] = useState<number>(score)

  const ratingLabels = (score: number) => {
    switch (score) {
      case 1:
        return 'Buruk'
      case 2:
        return 'Cukup'
      case 3:
        return 'Baik'
      case 4:
        return 'Sangat Baik'
      default:
        return 'Berikan Penilaian'
    }
  }

  const getStarSize = () => {
    switch (size) {
      case 'tiny':
        return 14
      case 'small':
        return 18
      default:
        return 24
    }
  }

  const onRatingChange = (e: ChangeEvent<HTMLInputElement>) => {
    setIsClicked(true)
    setSelectedStar(parseInt(e.target.value))
    setClickedScore(parseInt(e.target.value))
    onChange?.(e)
  }

  const handleMouseEnter = (index: number) => {
    if (!isEditable) {
      return
    }
    setSelectedStar(index)
  }

  const handleMouseLeave = () => {
    if (!isEditable) {
      return
    }
    setSelectedStar(isClicked ? clickedScore : 0)
  }

  const starComponent = () => {
    const starItems: ReactNode[] = []
    for (let index = starCount; index > 0; index--) {
      starItems.push(
        <>
          <input
            type="radio"
            id={`${id}_star${index}`}
            name={id}
            value={index}
            checked={score < index + 1 && score >= index}
            disabled={!isEditable}
            className="hidden"
            onChange={(e) => onRatingChange(e)}
          />
          {isBolder ? (
            <span className="bolder">
              <Star fill="#A8ADB7" size={getStarSize()} />
            </span>
          ) : (
            <label
              className={cx(!isEditable && 'disabled')}
              htmlFor={`${id}_star${index}`}
              title={ratingLabels(index)}
              onMouseEnter={() => handleMouseEnter(index)}
              onMouseLeave={handleMouseLeave}
            >
              <Star size={getStarSize()} />
            </label>
          )}
        </>,
      )
    }

    return <div className={cx(styles.rating, size === 'small' && styles.rating_sm)}>{starItems}</div>
  }

  return (
    <div className={cx(styles.wrapper, className, !isEditable && styles.disabled)} {...rest}>
      {starComponent()}
      {withDescription && (
        <div className={cx(styles.caption, selectedStar > 0 && isClicked && styles.caption_active)}>
          {ratingLabels(selectedStar)}
        </div>
      )}
    </div>
  )
}
