'use client'
import { format } from 'date-fns'
import { forwardRef, useRef, useState } from 'react'
import { DayPicker } from 'react-day-picker'
import { usePopper } from 'react-popper'
import TextField from '../TextField'
import { Calendar } from 'react-feather'
import styles from './DatePicker.module.css'
import 'react-day-picker/dist/style.css'
import { cx } from 'class-variance-authority'
import { TDatePickerProps } from './DatePicker.type'
import { id } from 'date-fns/locale'
import useForkRef from 'shared-utils/src/useForkRef'
import useClickOutside from 'shared-utils/src/useClickOutside'
import Dropdown from './Dropdown'
const DatePicker = forwardRef<HTMLInputElement, TDatePickerProps>(function (props, ref) {
  const {
    textFieldProps,
    disabled,
    formatDisplay = 'y-MM-dd',
    value,
    onChange,
    caption,
    variant,
    defaultPlacement,
    defaultValue,
    defaultMonth,
    disableInitialFocus = false,
    ...rest
  } = props

  const [selected, setSelected] = useState<Date | undefined>(defaultValue)
  const [isPopperOpen, setIsPopperOpen] = useState(false)

  const wrapperRef = useRef<HTMLDivElement>(null)
  const popperRef = useRef<HTMLDivElement>(null)
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null)

  const popper = usePopper(popperRef.current, popperElement, {
    placement: defaultPlacement || 'top-start',
  })

  const inputRef = useRef<HTMLInputElement>()
  const handleRef = useForkRef(inputRef, ref)

  const closePopper = () => {
    setIsPopperOpen(false)
  }
  const handleInputOnClick = () => {
    setIsPopperOpen(true)
  }

  const handleDaySelect = (date: Date | undefined) => {
    setSelected(date)

    if (date) {
      onChange?.(date)
      closePopper()
    }
  }
  useClickOutside(isPopperOpen ? wrapperRef : undefined, closePopper)

  let textFieldValue = ''

  const activeDate = value ?? selected

  if (activeDate) {
    textFieldValue = format(activeDate, formatDisplay, { locale: id })
  }

  return (
    <div ref={wrapperRef}>
      <div ref={popperRef}>
        <TextField
          ref={handleRef}
          {...textFieldProps}
          wrapperProps={{
            className: cx(styles.textfield, textFieldProps?.wrapperProps?.className),
            ...textFieldProps?.wrapperProps,
          }}
          className={cx(textFieldProps?.className, 'cursor-pointer caret-transparent')}
          disabled={disabled}
          iconLeft={Calendar}
          value={textFieldValue}
          onFocus={handleInputOnClick}
          variant={variant}
          caption={caption}
          contentEditable={false}
          readOnly
        />
      </div>
      {isPopperOpen && (
        <div
          tabIndex={-1}
          style={popper.styles.popper}
          className="z-10"
          {...popper.attributes.popper}
          ref={setPopperElement}
          role="dialog"
        >
          <DayPicker
            weekStartsOn={1}
            locale={id}
            disabled={disabled}
            className={cx(styles.datePicker)}
            initialFocus={disableInitialFocus ? undefined : isPopperOpen}
            showOutsideDays
            mode="single"
            defaultMonth={activeDate || defaultMonth}
            selected={activeDate}
            onSelect={handleDaySelect}
            components={{
              ...rest.components,
              Dropdown: (dropdownProps) =>
                rest.captionLayout === 'dropdown-buttons' ? <Dropdown {...dropdownProps} /> : null,
            }}
            {...rest}
          />
        </div>
      )}
    </div>
  )
})

export default DatePicker
