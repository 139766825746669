'use client'
import { PropsWithChildren } from 'react'
import type { ModalProps } from './Modal.type'
import * as Dialog from '@radix-ui/react-dialog'
import { cx } from 'class-variance-authority'
import styles from './Modal.module.css'
import { X } from 'react-feather'
export default function Modal({
  trigger,
  title,
  closable,
  children,
  classNames,
  modalId,
  onOpenAutoFocus,
  ...dialogProps
}: PropsWithChildren<ModalProps>) {
  return (
    <Dialog.Root {...dialogProps}>
      {trigger && <Dialog.Trigger asChild>{trigger}</Dialog.Trigger>}
      <Dialog.Portal>
        <Dialog.Overlay className={cx(styles.overlay, classNames?.overlay)} />

        <Dialog.Content
          onOpenAutoFocus={onOpenAutoFocus}
          className={cx(styles.content, classNames?.content)}
          id={modalId}
        >
          <header className={cx(styles.header, classNames?.header)}>
            {title && <Dialog.Title className={cx(styles.title, classNames?.title)}>{title}</Dialog.Title>}
            {closable && (
              <Dialog.Close asChild>
                <button className={styles.closeButton} id="close-button">
                  <X size={24} />
                </button>
              </Dialog.Close>
            )}
          </header>

          {children}
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  )
}
Modal.Close = Dialog.Close
