/* eslint-disable tailwindcss/no-custom-classname */
/* eslint-disable tailwindcss/classnames-order */
/* eslint-disable multiline-ternary */
'use client'

import { cx } from 'class-variance-authority'
import { type UIEvent, useState } from 'react'
import { Document, Page, pdfjs } from 'react-pdf'

import FooterAction from './FooterAction'
import { TDocumentState, TPdfViewer, ZOOM_INDEXES } from './PdfViewer.types'
import Spinner from '../Spinner'
import useDownloadPdf from './hooks/useDownloadPdf'

pdfjs.GlobalWorkerOptions.workerSrc = new URL('pdfjs-dist/build/pdf.worker.min.js', import.meta.url).toString()

const PdfViewer = ({ url, enableDownload }: TPdfViewer) => {
  const [shouldScroll, setShouldScroll] = useState(true)
  const [documentState, setDocumentState] = useState<TDocumentState>({
    page: 1,
    scaleIndex: 7,
    isError: false,
  })
  const [renderedScale, setRenderedScale] = useState<number[]>([])

  const onError = () => {
    setDocumentState((prev) => ({ ...prev, isError: true, isLoading: false }))
  }
  const {
    data: fileData,
    isFetching: loading,
    error,
  } = useDownloadPdf({
    variables: url,
  })

  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
    setDocumentState((prev) => ({ ...prev, numPages, isLoading: false }))
    setRenderedScale(Array.from({ length: numPages }).map(() => 7))
  }

  const handleOnScroll = (event: UIEvent<HTMLDivElement>) => {
    if (shouldScroll) {
      const sections = document.querySelectorAll<HTMLElement>('div[data-page-number]')
      sections.forEach((section) => {
        const sectionTop = section.offsetTop > 300 ? section.offsetTop - 200 : section.offsetTop
        const sectionHeight = section.offsetHeight > 300 ? section.offsetHeight - 200 : section.offsetHeight

        if (event.currentTarget.scrollTop >= sectionTop && event.currentTarget.scrollTop < sectionTop + sectionHeight) {
          setDocumentState((prev) => ({
            ...prev,
            page: parseInt(section.className.split(' ')[1] ?? ''),
          }))
        }
      })
    }
  }

  const handleOnZoomDoc = (action: 'minus' | 'plus') => () => {
    setDocumentState((prev) => ({
      ...prev,
      scaleIndex: prev.scaleIndex + (action === 'plus' ? 1 : -1),
    }))
  }

  const handleChangePage = (action: 'minus' | 'plus') => () => {
    const deficit = action === 'plus' ? 1 : -1
    setShouldScroll(false)
    setDocumentState((prev) => {
      const container = document.querySelector('div.react-pdf__Document')
      if (container) {
        const targetEl = container.querySelector(`div[data-page-number='${prev.page + deficit}']`)
        targetEl && targetEl.scrollIntoView()
      }
      return {
        ...prev,
        page: prev.page + deficit,
      }
    })
    setTimeout(() => setShouldScroll(true), 100)
  }

  const onRenderSuccess = (idx: number) => {
    setRenderedScale((prev) =>
      prev.map((item, index) => {
        if (idx === index) {
          return documentState.scaleIndex
        }
        return item
      }),
    )
  }

  if (error || documentState.isError) {
    return (
      <div className="mx-auto mt-6 flex h-28 w-full max-w-[1440px] items-center gap-2 md:px-4 lg:px-10 xl:px-[118px]">
        <div className="flex min-w-0 flex-1 items-center justify-center gap-4 self-stretch bg-tertiary25 px-17 py-6">
          <h2 className="text-center text-lg font-bold text-tertiary400">
            Telah terjadi kesalahan dalam memuat dokumen. Pastikan extension browser mu telah dimatikan sebelum unduh
            dokumen.
          </h2>
        </div>
      </div>
    )
  }

  if (loading) {
    return (
      <div className="mt-5 flex h-28 w-full items-center justify-center">
        <Spinner color="primary" size="medium" />
      </div>
    )
  }

  return (
    <>
      <div className="mx-auto mb-4 flex w-full max-w-[1440px] items-center gap-2 md:px-4 lg:px-10 xl:px-[118px]">
        <Document
          file={fileData}
          onSourceError={onError}
          onLoadSuccess={onDocumentLoadSuccess}
          onLoadError={onError}
          className={cx(
            (ZOOM_INDEXES[documentState.scaleIndex] || 1) < 0.5 ? 'gap-4' : 'gap-8',
            'mt-6 flex flex-col overflow-auto',
            'max-h-[calc(100vh-248px)] w-full rounded-4 bg-tertiary25 py-11',
            'relative',
          )}
          loading={
            <div className="mt-5 flex h-28 w-full items-center justify-center">
              <Spinner color="primary" size="medium" />
            </div>
          }
          noData={<h2>Telah terjadi kesalahan dalam memuat dokumen... </h2>}
          onScroll={handleOnScroll}
        >
          {
            // Render 2 page at each index to prevent flickering on zooming the document
            Array.from({ length: documentState.numPages || 0 }).map((_, idx) => {
              return (
                <>
                  {renderedScale[idx] !== documentState.scaleIndex ? (
                    <Page
                      pageNumber={idx + 1}
                      height={1194}
                      className={cx(`${idx + 1}`, 'flex justify-center !bg-tertiary25')}
                      renderTextLayer={false}
                      renderAnnotationLayer={false}
                      key={`page-${idx}-${renderedScale[idx]}`}
                      scale={ZOOM_INDEXES[renderedScale[idx] || 0] ?? 1}
                      loading={<Spinner color="primary" size="medium" />}
                    />
                  ) : null}
                  <Page
                    pageNumber={idx + 1}
                    height={1194}
                    className={cx(`${idx + 1}`, 'flex justify-center !bg-tertiary25')}
                    renderTextLayer={false}
                    renderAnnotationLayer={false}
                    key={`page-${idx}-${documentState.scaleIndex}`}
                    scale={ZOOM_INDEXES[documentState.scaleIndex] ?? 1}
                    onRenderSuccess={() => onRenderSuccess(idx)}
                    loading={<Spinner color="primary" size="medium" />}
                  />
                </>
              )
            })
          }
        </Document>
      </div>
      <FooterAction
        url={url}
        onZoom={handleOnZoomDoc}
        enableDownload={enableDownload}
        documentState={documentState}
        onChangePage={handleChangePage}
      />
    </>
  )
}

export default PdfViewer
