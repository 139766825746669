'use client'

import StarterKit from '@tiptap/starter-kit'
import Underline from '@tiptap/extension-underline'
import Placeholder from '@tiptap/extension-placeholder'
import CharacterCount from '@tiptap/extension-character-count'
import { EditorContent, useEditor } from '@tiptap/react'
import { cx } from 'class-variance-authority'
import Toolbar from './Toolbar'

type TRichTextEditorProps = {
  getCharacterCount?: (count: number) => void
  limit?: number | null
  onChange?: (richText: string) => void
  placeholder?: string
  styles?: {
    wrapper?: string
    editor?: string
  }
  value?: string
  variant?: 'normal' | 'error'
}

const RichTextEditor = ({
  onChange,
  placeholder,
  styles,
  value,
  variant,
  getCharacterCount,
  limit = null,
}: TRichTextEditorProps) => {
  const isError = variant === 'error'
  const editor = useEditor({
    extensions: [
      Underline,
      CharacterCount.configure({
        limit,
      }),
      StarterKit.configure({
        bulletList: {
          keepMarks: true,
          keepAttributes: false,
        },
        orderedList: {
          keepMarks: true,
          keepAttributes: false,
        },
      }),
      Placeholder.configure({
        placeholder: placeholder || 'Write something …',
      }),
    ],
    onUpdate: ({ editor: content }) => {
      const value = content.isEmpty ? '' : content.getHTML()
      getCharacterCount && getCharacterCount(content.storage.characterCount.characters())
      onChange && onChange(value)
    },
    content: value,
    editorProps: {
      attributes: {
        class: cx(styles?.editor, 'min-h-[150px] prose prose-sm m-5 focus:outline-none'),
      },
    },
  })

  if (!editor) {
    return null
  }

  return (
    <div
      className={cx(
        styles?.wrapper,
        isError ? 'border-error500 focus-within:border-error500' : 'border-tertiary100 focus-within:border-tertiary200',
        'group h-fit w-full rounded-4 border border-solid',
      )}
    >
      <Toolbar editor={editor} isError={isError} />
      <EditorContent editor={editor} />
    </div>
  )
}

export default RichTextEditor
